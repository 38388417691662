import React, { useState } from "react"
import HomePageLayout from "../../components/layouts/HomePageLayout"
import SEO from "../../components/seo"
import generalClasses from "./general.module.css"

import websiteImage from "../../images/case-studies/proworks.jpg"
import Footer from "../../components/footer"
import RequestModal from "../../components/requestModal"

import { Link } from "gatsby"
import arrowLeft from "../../images/arrow-left.svg"
import RightIcon from "../../images/arrow-right.inline.svg"

const Prowoks = props => {
  const [modalOpen, toggleModalOpen] = useState(false)

  return (
    <HomePageLayout pageTitle="Prowoks" background>
      <SEO title="Prowoks Case Study" />
      <main className={generalClasses.main}>
        <Link to={"/solutions/products"} className={generalClasses.backLink}>
          <img
            src={arrowLeft}
            alt=""
            className={generalClasses.backLinkImage}
          />
          Products
        </Link>
        <section
          className={[
            generalClasses.caseStudyIntro,
            generalClasses.section,
          ].join(" ")}
        >
          <div>
            <h1 className={generalClasses.caseStudyHeader}>prowoks</h1>
            <p className={generalClasses.caseStudyDescription}>
              Prowoks is an electronic document management solution that helps
              to create, archive, track, approve and review documents.
            </p>
            <p className={generalClasses.caseStudyCategory}>
              UI / UX | WEB DEVELOPMENT
            </p>
          </div>
          <ul className={generalClasses.caseStudyDetails}>
            <li className={generalClasses.caseStudyDetail}>
              <h4 className={generalClasses.caseStudyDetailHeader}>CLIENT</h4>
              <p className={generalClasses.caseStudyDetailContent}>
                Ondo State Government
              </p>
            </li>
            <li className={generalClasses.caseStudyDetail}>
              <h4 className={generalClasses.caseStudyDetailHeader}>LOCATION</h4>
              <p className={generalClasses.caseStudyDetailContent}>
                Ondo, Nigeria
              </p>
            </li>
            <li className={generalClasses.caseStudyDetail}>
              <h4 className={generalClasses.caseStudyDetailHeader}>USERS</h4>
              <p className={generalClasses.caseStudyDetailContent}>
                201 - 500 Users
              </p>
            </li>
            <li className={generalClasses.caseStudyDetail}>
              <h4 className={generalClasses.caseStudyDetailHeader}>INDUSTRY</h4>
              <p className={generalClasses.caseStudyDetailContent}>
                Technology
              </p>
            </li>
          </ul>
        </section>
        <section className={generalClasses.section}>
          <h1 className={generalClasses.meetCaseStudyHeader}>
            Meet the ondo state government
          </h1>
          <p className={generalClasses.meetCaseStudyText}>
            Ondo state is located in the tropical forest of South western part
            of Nigeria, bounded in the north by Ekiti and Kogi states, in the
            east by Edo state, in the west by Osun and Ogun states and in the
            south by the Atlantic ocean. Ondo state is richly blessed with
            copious natural resources waiting to be tapped. The strategic
            location of the state coupled with its peaceful and safe environment
            makes it very attractive to investors.
          </p>
        </section>
        <section
          className={[generalClasses.grid, generalClasses.section].join(" ")}
        >
          <div>
            <h1 className={generalClasses.sectionHeader}>our idea</h1>
            <p className={generalClasses.sectionText}>
              We thought about the pain points of the client of not been able to
              monitor daily tasks of workers so we decided to profer a solution
              that will both monitor and evaluate their performances regularly.
            </p>
          </div>
          <div>
            <h1 className={generalClasses.sectionHeader}>RESULTS</h1>
            <p className={generalClasses.sectionText}>
              After critically thinking through the process and how to solve the
              problem, we built an intuitively crafted website which was
              delivered to the Ondo State Government to ease the process of
              monitoring and evaluating the progress of their tasks.
            </p>
          </div>
        </section>
        <img
          src={websiteImage}
          alt=""
          className={generalClasses.displayImageFull}
        />
        <div className={generalClasses.caseStudyPageLinks}>
          <Link
            to="/case-studies/totalmetrics"
            className={[
              generalClasses.caseStudyPageLink,
              generalClasses.caseStudyPageLinkRight,
            ].join(" ")}
          >
            <p className={generalClasses.caseStudyPageLinkText}>
              <span className={generalClasses.caseStudyPageLinkTag}>NEXT</span>
              <span className={generalClasses.caseStudyPageLinkTitle}>
                Total Metrics
              </span>
            </p>
            <span className={generalClasses.caseStudyPageLinkIcon}>
              <RightIcon
                className={generalClasses.caseStudyPageLinkIconImage}
                width="11px"
                height="18px"
              />
            </span>
          </Link>
        </div>
        <section
          className={[generalClasses.requestQuote, generalClasses.section].join(
            " "
          )}
        >
          <h1
            className={[
              generalClasses.sectionHeader,
              generalClasses.requestQuoteHeader,
            ].join(" ")}
          >
            learn more about prowoks
          </h1>
          <button
            className={generalClasses.requestQuoteButton}
            onClick={() => toggleModalOpen(true)}
          >
            REQUEST A QUOTE
          </button>
        </section>
        <RequestModal open={modalOpen} toggleOpen={toggleModalOpen} />
      </main>
      <Footer />
    </HomePageLayout>
  )
}

export default Prowoks
