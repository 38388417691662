import React, { useCallback } from "react"
import classNames from "classnames"

import "./TextInput.css"

export const TextInput = ({
  label,
  type = "text",
  value,
  onChange,
  required = false,
  className,
  ...props
}) => {
  const handleChange = useCallback(
    event => {
      const {
        target: { value },
      } = event
      onChange(value, event)
    },
    [onChange]
  )
  const hasContent = !!value && value.length > 0
  return (
    <div className={classNames("TextInput", className)}>
      <label
        className={classNames(
          "TextInput__label",
          hasContent && "TextInput__label--has-content",
          !hasContent && required && "TextInput__label--is-required"
        )}
      >
        {label}
      </label>
      <input
        className="TextInput__input"
        type={type}
        value={value}
        onChange={handleChange}
        aria-label={label}
        required={required}
        {...props}
      />
    </div>
  )
}
