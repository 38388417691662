import React, { useState } from "react"
import classes from "./requestmodal.module.css"
import { TextInput } from "./form/TextInput"
import closeIcon from "../images/close.png"

const RequestModal = ({ open, toggleOpen }) => {
  const [value, changeValue] = useState("")
  const [show, toggleShow] = useState(true)

  return (
    <>
      <div
        className={[
          classes.requestModalContainer,
          open && classes.requestModalContainerActive,
        ].join(" ")}
      >
        <form
          className={[
            classes.requestModal,
            open && show && classes.requestModalActive,
          ].join(" ")}
        >
          <button
            className={classes.requestModalCloseButton}
            type="button"
            onClick={() => toggleOpen(false)}
          >
            <img src={closeIcon} alt="" />
          </button>
          <p className={classes.requestModalText}>
            Enter your email to request for a quote
          </p>
          <TextInput
            label="Email *"
            value={value}
            onChange={value => changeValue(value)}
            className={classes.formInput}
            required
          />
          <div className={classes.formButtons}>
            <button
              className={classes.formButton}
              onClick={() => {
                toggleShow(false)
                setTimeout(() => {
                  toggleOpen(false)
                  toggleShow(true)
                }, 350)
              }}
              type="button"
            >
              CANCEL
            </button>
            <button
              className={[classes.formButton, classes.formButtonRequest].join(
                " "
              )}
              type="submit"
            >
              REQUEST NOW
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default RequestModal
